import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

// Containers
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const SingleSearchResultEventContainer = styled.div`
  & .content-container {
    padding: 0 50px;

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  & .single-current-event-container {
    margin: 0 0 100px 0;

    @media (max-width: 768px) {
      margin: 0 0 45px 0;
    }

    &:last-of-type {
      margin: 0 0 70px 0;

      @media (max-width: 768px) {
        margin: 0 0 30px 0;
      }
    }

    & .banner-image-container {
      margin: 0 0 50px 0;

      // & .gatsby-image-wrapper {
      //   display: block;
      //   width: 100%;
      // }

      & .gatsby-image-wrapper-constrained {
        width: 100%;
      }

      @media (max-width: 768px) {
        margin: 0 0 15px 0;
      }
    }

    & .text-container {
      max-width: 875px;
      margin: 0 auto;

      & h1,
      & h2 {
        font-size: 22px;
        line-height: 32px;

        text-align: center;

        color: #0b646f;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      & p {
        color: #0b646f;

        font-size: 16px;
        line-height: 23px;

        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 17px;
        }
      }

      & .text {
        margin: 50px 0;

        @media (max-width: 768px) {
          margin: 20px 0;
        }

        & p {
          color: #0b646f;

          font-size: 16px;
          line-height: 23px;

          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 17px;
          }

          &.event-link {
            & a {
              display: block;

              color: #0b646f;
              font-size: 10px;
              line-height: 32px;
              text-align: center;
              letter-spacing: 0.02em;

              min-width: 120px;
              width: fit-content;
              height: 30px;

              padding: 0 20px;

              background: #f5eadf;

              font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
                "Lucida Grande", sans-serif;

              @media (hover: hover) and (pointer: fine) {
                transition: 250ms color ease, 250ms background-color ease;

                &:hover {
                  color: #fff;
                  background-color: #0b646f;
                }
              }
            }
          }
        }
      }
    }
  }

  & .products-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;

      & .single-product {
        &:last-of-type {
          display: none;
        }
      }
    }

    & .single-product {
      display: grid;
      grid-template-rows: auto auto auto;
      height: 100%;

      & p {
        margin: 0;
      }

      & .product-image {
      }

      & .product-title {
        text-align: center;

        font-size: 12px;
        line-height: 17px;

        color: #0b646f;

        margin: 20px 0;

        @media (max-width: 768px) {
          font-size: 11px;
          line-height: 16px;
        }
      }

      & .product-buttons {
        text-align: center;
        margin: auto 0 0 0;

        & button {
          width: 120px;
          height: 30px;

          font-size: 10px;
          line-height: 32px;

          text-align: center;
          letter-spacing: 0.02em;

          color: #0b646f;

          background-color: #f5eadf;

          border: 0;
          padding: 0;

          @media (hover: hover) and (pointer: fine) {
            transition: 250ms color ease, 250ms background-color ease;

            &:hover {
              color: #fff;
              background-color: #0b646f;
            }
          }
        }
      }
    }
  }
`;

export const SingleSearchResultEvent = ({ event, isDesktop, index }) => {
  return (
    <SingleSearchResultEventContainer>
      <div className="single-current-event-container">
        <div className="banner-image-container">
          {isDesktop ? (
            <AspectRatioImageContainer image={null} padding={35.748}>
              <GatsbyImage
                image={event.data.primary.banner_image.gatsbyImageData}
                alt={
                  event.data.primary.banner_image.alt !== null
                    ? event.data.primary.banner_image.alt
                    : ""
                }
                draggable="false"
                loading="lazy"
              />
            </AspectRatioImageContainer>
          ) : (
            <GatsbyImage
              image={event.data.primary.mobileImage.gatsbyImageData}
              alt={
                event.data.primary.mobileImage.alt !== null
                  ? event.data.primary.mobileImage.alt
                  : ""
              }
              draggable="false"
              loading="lazy"
            />
          )}
        </div>

        <div className="content-container">
          <div className="text-container">
            <div className="title">
              <PrismicRichText
                field={event.data.primary.event_title.richText}
              />
            </div>

            <div className="dates">
              <PrismicRichText
                field={event.data.primary.event_dates.richText}
              />
            </div>

            <div className="text news-gothic">
              <PrismicRichText field={event.data.primary.event_text.richText} />

              <PrismicRichText
                field={event.data.primary.event_link.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p
                      className="event-link uppercase"
                      key={`single_product_row_p_${index}`}
                    >
                      {children}
                    </p>
                  ),
                }}
              />
            </div>
          </div>

          {/* {products.length >= 1 && (
          <div className="products-container">{products}</div>
        )} */}
        </div>
      </div>
    </SingleSearchResultEventContainer>
  );
};
