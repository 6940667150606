import React, { useEffect, useContext, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";
import { use100vh } from "react-div-100vh";

// Context
import { PageSetup } from "../components/context/page-context";
import { useDraftProductsContext } from "../components/context/draft-products";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { SingleSearchResultProduct } from "../components/product/single-product";
import { SingleSearchResultEvent } from "../components/events/single-search-result-event";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 75px 0 0 0;

  @media (max-width: 960px) {
    padding: 30px 0;
  }

  min-height: calc(${props => props.height}px - 50px);

  & .breadcrumbs {
    padding: 0 50px;

    grid-column: 1 / 13;

    & p {
      font-size: 12px;
      line-height: 28px;

      color: #0b646f;

      margin: 0 0 1em 0;
    }

    @media (max-width: 768px) {
      padding: 0 10px;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .artworks-container {
    margin: 0 0 90px 0;
  }

  & .section-title {
    margin: 0 0 75px 0;

    & h1 {
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      color: #0b646f;
    }
  }

  & .single-product {
    grid-column: span 4;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 100px;

  min-height: calc(${props => props.height}px - 50px);
  padding: 0 50px;

  @media (max-width: 960px) {
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-row-gap: unset;
  }
`;

const NoResults = styled.div`
  grid-column: 1 / 13;

  & .text-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    color: #0b646f;
    min-height: calc(${props => props.height}px - 75px - 40px - 40px - 40px);

    & h1 {
      font-size: 22px;
      line-height: 32px;
      text-align: center;

      max-width: 440px;

      & a {
        color: #debb9b;
      }
    }
  }
`;

const SearchPage = ({ location }) => {
  const [searchResults, updateSearchResults] = useState([]);
  const [artworkSearchResults, updateArtworkSearchResults] = useState([]);
  const [eventsSearchResults, updateEventsSearchResults] = useState([]);

  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Draft Products
  const { draftProducts } = useDraftProductsContext();

  const height = use100vh();

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#FFFFFF`,
      pageType: `search`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location.state !== null &&
      location.state !== undefined
    ) {
      const results = location.state.searchResults;

      const artworks = results
        .filter(type => type.type === `artwork`)
        .filter(
          product => draftProducts.indexOf(product.data.shopifyId) === -1
        );
      updateArtworkSearchResults(artworks);

      const events = results.filter(type => type.type === `event`);
      updateEventsSearchResults(events);

      updateSearchResults(results);
    } else {
      return null;
    }
  }, [location]);

  if (
    location !== null &&
    location.state !== null &&
    location.state !== undefined
  ) {
    return (
      <>
        <PageSeo seoTitle={`Search`} seoText={null} seoImage={null} />

        <Page height={height}>
          {location !== null &&
          location.state !== null &&
          location.state !== undefined ? (
            <>
              <Helmet
                title={`Search Results for '${location.state.searchQuery}'`}
                meta={[
                  {
                    name: "og:title",
                    content: `Search Results for '${location.state.searchQuery}'`,
                  },
                ]}
              />

              {searchResults.length === 0 ? (
                <NoResults height={height}>
                  <section className="breadcrumbs">
                    <p>
                      <Link to={`/`}>HOME</Link> → SEARCH → Search results for ‘
                      {location.state.searchQuery}’
                    </p>
                  </section>

                  <div className="text-container">
                    <h1>
                      Sorry, no results were found return to the{" "}
                      <Link to={`/shop/`}>shop</Link>
                    </h1>
                  </div>
                </NoResults>
              ) : (
                <div className="content-grid">
                  <section className="breadcrumbs">
                    <p>
                      <Link to={`/`}>HOME</Link> → SEARCH → Search results for ‘
                      {location.state.searchQuery}’
                    </p>
                  </section>

                  {artworkSearchResults !== null &&
                    artworkSearchResults !== undefined && (
                      <>
                        {artworkSearchResults.length >= 1 && (
                          <section className="artworks-container">
                            <div className="section-title">
                              <h1>Artworks</h1>
                            </div>

                            <Grid height={height}>
                              {artworkSearchResults.map((product, index) => (
                                <SingleSearchResultProduct
                                  product={product}
                                  index={index}
                                  key={`single_product_${index}_${product.id}`}
                                />
                              ))}
                            </Grid>
                          </section>
                        )}
                      </>
                    )}

                  {eventsSearchResults !== null &&
                    eventsSearchResults !== undefined && (
                      <>
                        {eventsSearchResults.length >= 1 && (
                          <section className="events-container">
                            <div className="section-title">
                              <h1>Events</h1>
                            </div>

                            {eventsSearchResults.map((event, index) => (
                              <SingleSearchResultEvent
                                event={event}
                                index={index}
                                key={`single_event_${index}_${event.id}`}
                                isDesktop={isDesktop}
                              />
                            ))}
                          </section>
                        )}
                      </>
                    )}
                </div>
              )}
            </>
          ) : (
            <NoResults height={height}>
              <div className="text-container">
                <h1>
                  Sorry, no results were found return to the{" "}
                  <Link to={`/shop/`}>shop</Link>
                </h1>
              </div>
            </NoResults>
          )}
        </Page>
      </>
    );
  } else {
    return (
      <>
        <Helmet
          title={`Search Results`}
          meta={[
            {
              name: "og:title",
              content: `Search Results`,
            },
          ]}
        />
        <Page>
          <Grid>
            <NoResults height={height}>
              <div className="text-container">
                <h1>
                  Sorry, no results were found return to the{" "}
                  <Link to={`/shop/`}>shop</Link>
                </h1>
              </div>
            </NoResults>
          </Grid>
        </Page>
      </>
    );
  }
};

export default SearchPage;
